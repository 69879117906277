<template>
	<div class="modal4" :class="{open: isOpen}"  @click="clickedModal">
		<div class="modal4-content" :class="className" :style="customStyle">

			<button class="modal4-close" @click.prevent="close">
				<ion-icon name="close"></ion-icon>
			</button>
			
			<slot />
			
		</div>
	</div>
</template>

<script>
	export default {
		props: ['className', 'customStyle'],
		data() {
			return {
				isOpen: false
			}
		},
		methods: {
			clickedModal(event) {
				if (event.target.matches('.modal4')) {
					// this.close();
				}
			},
			close() {
				this.isOpen = false;
				this.$emit('close');
			},
			open() {
				this.isOpen = true;
				this.$emit('open');
			}
		}
	}
</script>